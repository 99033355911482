<template>
  <v-card
    no-body
    :flat="true"
    class="cardCont"
    style="background-color: #283046"
  >
    <!-- form -->
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="auth-login-form"
      >
        <!-- email -->
        <v-text-field
          v-model.trim="account"
          dark
          placeholder="请输入您的登录手机号"
          prepend-icon="mdi-cellphone"
          :rules="[required, isPhone]"
          dense
          outlined
          class="mt-10 mb-6"
        />

        <!-- password -->
        <div class="d-flex justify-content-between code">
          <v-text-field
            v-model="code"
            placeholder="请输入验证码"
            :rules="[required]"
            dark
            type="text"
            prepend-icon="mdi-shield-lock-open"
            dense
            outlined
          />
          <v-btn
            text
            color="#7367f0"
            @click="sendVerificationCode"
          >
            {{ tipText }}
          </v-btn>
        </div>

        <!-- submit button -->
        <v-btn
          color="#7367f0"
          block
          class="mt-4 mb-1 text-white font-medium-1"
          @click="login()"
        >
          登录
        </v-btn>
      </v-form>
    </v-card-text>

    <Vcode
      :show="isShow"
      @success="onSuccess"
      @close="onClose"
    />
  </v-card>
</template>

<script>
import { required, isPhone } from '@core/utils/validation'
import Vcode from 'vue-puzzle-vcode'
import useJwt from '@/auth/jwt/useJwt'
import { ref, onMounted, computed } from '@vue/composition-api'
import qs from 'qs'
import { modalFail, toastFail, toastSuccess } from '@core/utils/prompt'
import axios from 'axios'
import { useRouter } from '@core/utils'
import config from '../../../config'

export default {
  components: {
    Vcode,
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const valid = ref(false)
    const account = ref('')
    const code = ref('')
    const status = ref('')
    const errorMsg = ref('')
    const tipText = ref('发送验证码')
    const codeStatus = ref(false)
    const time = ref()
    const step = ref(60)
    const isShow = ref(false)
    const { router, route } = useRouter()
    const query = computed(() => route.value.query)

    function countdown() {
      if (time.value) clearTimeout(time.value)
      time.value = setTimeout(() => {
        if (step.value > 0) {
          step.value -= 1
          tipText.value = `剩余时间:${step.value}秒`
          countdown()
        } else {
          clearTimeout(time.value)
          tipText.value = '发送验证码'
          codeStatus.value = false
          step.value = 60
        }
      }, 1000)
    }

    function sendVerificationCode() {
      if (codeStatus.value) return toastFail('已发送请勿重复点击')
      const phoneReg = /^1[3456789]\d{9}$/
      if (!account.value || !phoneReg.test(account.value)) return toastFail('请填写正确的手机号')

      axios.post(`${config.serviceUrl}/sso/sendLoginMsg`, { mobile: account.value })
        .then(res => {
          const { data, code } = res.data
          if (code === 200) {
            toastSuccess(data || '已发送')
            codeStatus.value = true
            countdown()
          }
        }).catch(error => {
          toastFail(error.response.data.message || '未知错误')
        })

      return false
    }

    function change(num) {
      this.$emit('update:actived', num)
    }
    const validate = () => {
      form.value.validate()
    }

    // 获取时间戳
    function getCurrentTimestamp() {
      return Date.now()
    }

    // 是否在5分钟间隔内
    function calculateMinutesDiff(startTime, endTime) {
      const start = new Date(startTime)
      const end = new Date(endTime)
      const diffMilliseconds = end - start

      return Math.floor(diffMilliseconds / (1000 * 60)) <= 5
    }

    const login = () => {
      if (valid.value) {
        useJwt.ssoLogin(qs.stringify({
          name: account.value,
          pwd: code.value,
        }))
          .then(response => {
            if (response.data.code === 200) {
              localStorage.removeItem('stepTime')
              localStorage.removeItem('accessToken')
              localStorage.setItem('ssoToken', response.data.data.token)
              localStorage.setItem('ssoUser', JSON.stringify(response.data.data.user))
              localStorage.setItem('ssoAPP', JSON.stringify(response.data.data.user.appList || []))

              // if (query.value.redirect) {
              //   emit('login')
              // } else {
              //   router.replace('/choseSys')
              // }

              router.replace('/choseSys')
            } else {
              toastFail(response.data.msg)
              if (localStorage.getItem('stepTime')) {
                const data = JSON.parse(localStorage.getItem('stepTime'))
                const { phone, time, step } = data

                // 对比手机号
                if (phone !== String(account.value)) {
                  const datas = {
                    phone: account.value,
                    step: 1,
                    time: getCurrentTimestamp(),
                  }
                  localStorage.setItem('stepTime', JSON.stringify(datas))

                  return
                }

                // 比较时间戳
                const start = getCurrentTimestamp()
                const ent = Number(time)

                // 超过
                if (calculateMinutesDiff(ent, start) && Number(step) <= 4) {
                  const datasx = {
                    phone,
                    step: Number(step) + 1,
                    time,
                  }
                  localStorage.setItem('stepTime', JSON.stringify(datasx))
                } else {
                  isShow.value = true
                }
              } else {
                const data = {
                  phone: account.value,
                  step: 1,
                  time: getCurrentTimestamp(),
                }
                localStorage.setItem('stepTime', JSON.stringify(data))
              }
            }
          })
          .catch(error => {
            modalFail(error.response.data.message)
            const errorArr = error.response.data.errors
            for (let i = 0; i < errorArr.length; i += 1) {
              const errKey = errorArr[i].key
              const errMessage = errorArr[i].message
              const errArr = {
                [errKey]: errMessage,
              }
              this.$refs.loginForm.setErrors(errArr)
            }
          })
      }
      validate()
    }

    function onSuccess() {
      isShow.value = false
      localStorage.removeItem('stepTime')
    }

    function onClose() {
      isShow.value = false
    }

    onMounted(() => {
      if (localStorage.getItem('stepTime')) {
        localStorage.removeItem('stepTime')
      }
    })

    return {
      onSuccess,
      onClose,
      form,
      tipText,
      valid,
      account,
      code,
      status,
      errorMsg,
      isShow,
      change,
      login,
      isPhone,
      required,
      sendVerificationCode,
    }
  },
}
</script>

<style scoped>
.cardCont{
  font-family: Microsoft YaHei,sans-serif;
  height: 100%;
}
.auth-login-form{
  font-size: 14px;
}
</style>
