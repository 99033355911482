<script>
import {
  BCard, BCardImg, BCardBody, BLink, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import {
  ref, onUnmounted, computed, onMounted,
} from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'
import { useRouter } from '@core/utils'
import SSOPwdLogin from '@/views/sso-new/SSOPwdLogin.vue'
import SSODingLogin from '@/views/sso-new/SSODingLogin.vue'
import SSOLogged from '@/views/sso-new/SSOLogged.vue'
import storeModule from './storeModule'
import config from '../../config'

export default {
  name: 'NewSSOLogin',
  components: {
    SSOLogged,
    SSODingLogin,
    SSOPwdLogin,
    BCard,
    BCardImg,
    BCardTitle,
    BCardBody,
    BLink,
    BRow,
    BCol,
  },
  setup() {
    const { route } = useRouter()
    const isLogin = ref(false)
    const actived = ref(1)
    const redirect = computed(() => route.value.query.redirect)
    const SSO_LOGIN_MODULE_NAME = 'sso-login'
    const items = ref(['钉钉扫码登录', '验证码登录'])
    const tab = ref(0)

    // Register module
    if (!store.hasModule(SSO_LOGIN_MODULE_NAME)) store.registerModule(SSO_LOGIN_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SSO_LOGIN_MODULE_NAME)) store.unregisterModule(SSO_LOGIN_MODULE_NAME)
    })
    function getRedirectUrl() {
      const ssoToken = localStorage.getItem('ssoToken')

      if (!ssoToken) return false

      if (ssoToken) {
        axios.defaults.headers.Authorization = `Bearer ${ssoToken}`
      }

      axios.get(`${config.serviceUrl}/sso/redirectUrl?redirect=${redirect.value ? redirect.value : ''}`)
        .then(res => {
          const { code, data } = res.data
          if (code === 200) {
            isLogin.value = true
            store.commit('sso-login/getSuccess', '恭喜您，登录成功！')
            actived.value = 2
            if (redirect.value) {
              window.location.href = data
            }
          } else if (!redirect.value) {
            localStorage.removeItem('ssoToken')
          }
        })
    }

    onMounted(() => {
      document.title = '小五兄弟运营平台'
      getRedirectUrl()
    })

    return {
      isLogin,
      actived,
      items,
      tab,
      getRedirectUrl,
    }
  },
}
</script>

<template>
  <div class="container">
    <b-link class="brand-logo">
      <!-- logo -->
      小五云平台
    </b-link>

    <div class="auth-wrapper auth-v1 px-2">
      <b-card
        no-body
        class="overflow-hidden app-content"
        style="background-color: transparent; width: min-content"
      >
        <b-row
          no-gutters
          class="d-flex flex-nowrap"
          style="background-color: #283046"
        >
          <b-col
            xl="4"
            class="nd-none d-xl-block"
            style="min-height: 500px; min-width: 570px"
          >
            <b-card-img
              :src="require('@/assets/images/login/bg.png')"
              alt="Image"
              class="rounded-0"
            />
            <b-card-title>
              WELCOME TO XIAOWU
            </b-card-title>
          </b-col>
          <b-col
            xl="5"
            cols="12"
            style="height: 500px;width: 440px"
            class="flex-content"
          >
            <b-card-body>
              <div v-show="actived!==2">
                <v-tabs
                  v-model="tab"
                  dark
                  background-color="transparent"
                  grow
                >
                  <v-tab
                    v-for="item in items"
                    :key="item"
                    class="my8"
                  >
                    <span class="fs18">{{ item }}</span>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item class="tab-vessel">
                    <SSODingLogin :actived.sync="actived" />
                  </v-tab-item>

                  <v-tab-item class="tab-vessel">
                    <SSOPwdLogin
                      :actived.sync="actived"
                      @login="getRedirectUrl"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </div>

              <SSOLogged
                v-if="actived === 2"
                :actived.sync="actived"
              />
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<style scoped lang='scss'>
.container{
  height: 100%;
  min-height: 100vh;
  background-image: url("~@/assets/images/login/bg.gif");
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  max-width: 100%;
}

.brand-logo{
  position: absolute;
  left: 39px;
  top: 44px;
  display: flex;
  height: 48px;
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.card .card-title{
  position: absolute;
  width: 408px;
  height: 144px;
  top: 100px;
  left: 108px;
  font-size: 60px;
  color: white;
  line-height: 100px;
  font-weight: 500;
  text-align: center;
}
.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
}
.auth-v1 {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.px-2 {
  padding-left: 1.5rem;
}

.nd-none {
  display: none;
}
.flex-content {
  display: flex;
  align-items: center;
}
.tab-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #283046;
  height: 400px
}
.app-content {
  max-width: max-content;
}
</style>
