import axios from 'axios'
import config from '../../config'

export default {
  namespaced: true,
  state: {
    errMsg: '',
    resetMsg: '',
    successMsg: '',
  },
  getters: {},
  mutations: {
    getMsg(state, value) {
      state.errMsg = value
    },
    getReset(state, value) {
      state.resetMsg = value
    },
    getSuccess(state, value) {
      state.successMsg = value
    },
  },
  actions: {
    resetPwd(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/sso/resetPassword`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendVerificationCode(_, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${config.serviceUrl}/sso/sendLoginMsg`, data)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })

    }
  },
}
